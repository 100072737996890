import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Sidebar from 'core/components/sidebar/Sidebar';
import TopBar from 'core/components/TopBar';
import useAuth from 'core/hooks/useAuth';
import { logout } from 'core/services/auth';

const AppLayout: FC = () => {
  const user = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Sidebar />

      <main className="ml-16">
        <TopBar
          onLogout={() => {
            logout().catch(() => {
              // ..
            });
          }}
        />
        <Outlet />
      </main>
    </div>
  );
};

export default AppLayout;
