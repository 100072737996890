import axios from 'axios';

import Supplier, { ApiResponse } from 'supplier/models/Supplier';

const API_ROUTE = import.meta.env.VITE_APP_API_PATH;

export const getSupplierById = async (id: string): Promise<Supplier> => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    return Promise.reject(new Error('Auth token missing'));
  }

  const route = `${API_ROUTE}/suppliers/${id}`;

  const response = await axios.get<ApiResponse>(route, { headers: { Authorization: `Bearer ${token}` } });

  return response.data.data;
};
