import { useEffect, useState, useCallback, FC } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Tab } from 'core/components/Tabs';
import { getSupplierById } from 'core/services/supplier';

import Supplier from '../models/Supplier';

import ContactPersons from './components/ContactPersons';
import General from './components/General';
import HeaderCard from './components/HeaderCard';
import Notes from './components/Notes';

const SupplierDetailPage: FC = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const location = useLocation();
  const [supplierData, setSupplierData] = useState<Supplier | null>(null);
  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [activeTabContent, setActiveTabContent] = useState<React.ReactNode>(null);

  const setActiveTab = useCallback(
    (tabs: Tab[]) => {
      const params = new URLSearchParams(location.search);
      const tabKey = params.get('tab');

      const activeIndex = tabs.findIndex((tab) => tab.key === tabKey);
      if (activeIndex !== -1) {
        setActiveTabContent(tabs[activeIndex].content);
      } else {
        setActiveTabContent(tabs[0]?.content);
      }
    },
    [location]
  );

  useEffect(() => {
    if (supplierId) {
      getSupplierById(supplierId)
        .then((data) => {
          setSupplierData(data);
          setLoading(false);
        })
        .catch((_error: unknown) => {
          setLoading(false);
        });
    }
  }, [supplierId]);

  useEffect(() => {
    if (supplierData) {
      const newTabs = [
        {
          label: 'Základné údaje',
          key: 'general',
          path: `/suppliers/${supplierId}`,
          content: <General supplier={supplierData} />,
        },
        {
          label: 'Test',
          key: 'test',
          path: `/suppliers/${supplierId}`,
          content: <div className="m-6 rounded-md border border-gray-400 p-8">Test content</div>,
        },
      ];
      setTabs(newTabs);
      setActiveTab(newTabs); // Set the active tab when tabs are updated
    }
  }, [supplierData, supplierId, setActiveTab]);

  // Update active tab content when location changes
  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs);
    }
  }, [location, tabs, setActiveTab]);

  if (loading) {
    return <div>Načítavam...</div>;
  }

  return (
    <div>
      {supplierData && (
        <div>
          <HeaderCard supplier={supplierData} tabs={tabs} />
          <div className="tab-content">{activeTabContent}</div>
          <div className="m-6 grid grid-cols-1 gap-8 md:grid-cols-2">
            <div>
              <ContactPersons supplier={supplierData} />
            </div>
            <div>
              <Notes supplier={supplierData} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SupplierDetailPage;
