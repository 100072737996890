import { FC } from 'react';

interface Props {
  name: string;
  label: string;
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  isDisabled?: boolean;
  variant?: 'left' | 'right';
  isBold?: boolean;
}

const Switch: FC<Props> = ({
  name,
  label,
  isChecked,
  onChange,
  isDisabled = false,
  variant = 'right',
  isBold = false,
}) => {
  const switchElement = (
    <div className="relative mx-2">
      <input
        checked={isChecked}
        className="hidden"
        disabled={isDisabled}
        id={name}
        name={name}
        type="checkbox"
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <div
        className={`block h-6 w-10 cursor-pointer rounded-full transition-colors duration-300 ease-in-out ${
          isChecked ? 'bg-blue-500' : 'bg-gray-300'
        }`}
        onClick={() => {
          onChange(!isChecked);
        }}
        onKeyDown={() => {
          // TODO:
        }}
      >
        <div
          className={`dot absolute mt-1 size-4 rounded-full bg-white shadow transition-transform duration-300 ease-in-out ${
            isChecked ? 'translate-x-5' : 'translate-x-1'
          }`}
        />
      </div>
    </div>
  );

  return (
    <div className="flex items-center">
      {variant === 'left' && switchElement}
      <label className={`mb-1 text-sm leading-6 text-gray-900 ${isBold ? 'font-bold' : 'font-normal'}`} htmlFor={name}>
        {label}
      </label>
      {variant === 'right' && switchElement}
    </div>
  );
};

export default Switch;
