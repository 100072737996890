/* eslint-disable no-console */
import { FC } from 'react';
import { LiaIndustrySolid, LiaTimesSolid } from 'react-icons/lia';

import Button from './Button';
import Checkbox from './Checkbox';
import Input from './Input';
import Label from './Label';
import Select from './Select';
import Switch from './Switch';

interface FormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

// TODO create a modal form schema here and already defined inputs here move to the Supplier folder
const FormModal: FC<FormModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500/50">
      <div className="max-h-[90vh] w-full overflow-auto rounded-sm bg-white shadow-lg sm:w-11/12 md:w-4/5 lg:w-[900px] xl:max-w-5xl">
        <div className="flex items-center justify-between p-6">
          <LiaIndustrySolid className="size-10 text-blue-500" />
          <h2 className="mx-4 flex-1 text-xl font-bold">Vytvorenie nového dodávateľa</h2>
          <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
            <LiaTimesSolid size={20} />
          </button>
        </div>
        <hr />

        <form className="space-y-6 p-6">
          <div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div className="col-span-1">
                <Input
                  hasInlineLabel
                  isRequired
                  label="Názov spoločnosti"
                  name="companyName"
                  value=""
                  onChange={(value) => {
                    console.log(value);
                  }}
                />
              </div>
              <div className="col-span-1 space-x-4">
                <div className="inline-block w-[70%] align-middle">
                  <Input
                    hasInlineLabel
                    isRequired
                    label="IČO"
                    name="ico"
                    value=""
                    onChange={(value) => {
                      console.log(value);
                    }}
                  />
                </div>
                <div className="inline-block w-1/4 align-middle">
                  <Button isDisabled label="Finstat" />
                </div>
              </div>

              <div className="col-span-2 flex flex-wrap items-center space-x-4 sm:space-x-10">
                <Label hasInlineLabel isRequired label="Dodávateľ" name="supplier_checkboxes" />
                <Checkbox
                  isChecked={false}
                  label="Elektrická energia"
                  onChange={(value) => {
                    console.log(value);
                  }}
                />
                <Checkbox
                  isChecked={false}
                  label="Zemný plyn"
                  onChange={(value) => {
                    console.log(value);
                  }}
                />
              </div>
            </div>
          </div>

          <div>
            <div className="flex w-full items-center">
              <h3 className="mr-2 font-semibold">Sídlo / fakturačná adresa</h3>
              <div className="grow border-b border-blue-500"></div>
            </div>

            <div className="my-2 grid grid-cols-1 gap-4 sm:grid-cols-3">
              <div className="col-span-2">
                <Input
                  hasInlineLabel
                  isRequired
                  label="Ulica"
                  name="street"
                  value=""
                  onChange={(value) => {
                    console.log(value);
                  }}
                />
              </div>
              <div className="col-span-1">
                <Input
                  hasInlineLabel
                  isRequired
                  label="Číslo"
                  name="houseNumber"
                  value=""
                  onChange={(value) => {
                    console.log(value);
                  }}
                />
              </div>
            </div>

            <div className="my-2 grid grid-cols-1 gap-4 sm:grid-cols-3">
              <Select
                hasInlineLabel
                isRequired
                label="Obec"
                name="company_town"
                options={[]}
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
              <Input
                hasInlineLabel
                isRequired
                label="PSČ"
                name="postalCode"
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
              <Select
                hasInlineLabel
                isRequired
                label="Štát"
                name="country"
                options={[
                  { value: 'slovakia', label: 'Slovenská republika' },
                  { value: 'czech', label: 'Česká republika' },
                ]}
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
            </div>
            <div className="my-2 grid grid-cols-1 gap-4 sm:grid-cols-3">
              <Input
                hasInlineLabel
                label="DIČ"
                name="dic"
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
              <Input
                hasInlineLabel
                label="IČ DPH"
                name="icdph"
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
              <Switch
                isChecked={false}
                label="Platca DPH"
                name="dph_pay"
                onChange={(value) => {
                  console.log(value);
                }}
              />
            </div>
          </div>

          <div>
            <div className="flex w-full items-center">
              <h3 className="mr-2 font-semibold">Bankové údaje</h3>
              <div className="grow border-b border-blue-500"></div>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
              <Input
                hasInlineLabel
                label="IBAN"
                name="iban"
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
              <Select
                hasInlineLabel
                label="Banka"
                name="country"
                options={[]}
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
              <Input
                hasInlineLabel
                label="SWIFT/BIC"
                name="swift"
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
              <Input
                hasInlineLabel
                label="Splatnosť faktúr"
                name="paymentTerms"
                type="number"
                value={14}
                onChange={(value) => {
                  console.log(value);
                }}
              />
            </div>
          </div>

          <div>
            <div className="flex w-full items-center">
              <h3 className="mr-2 font-semibold">Korešpondenčná adresa</h3>
              <div className="grow border-b border-blue-500"></div>
            </div>

            <div className="my-4">
              <Switch
                isChecked={false}
                label="Rovnaká ako sídlo spoločnosti"
                name="same_as_company_address"
                onChange={(value) => {
                  console.log(value);
                }}
              />
            </div>

            <div className="my-2 grid grid-cols-1 gap-4 sm:grid-cols-3">
              <div className="col-span-2">
                <Input
                  hasInlineLabel
                  label="Ulica"
                  name="street"
                  value=""
                  onChange={(value) => {
                    console.log(value);
                  }}
                />
              </div>
              <div className="col-span-1">
                <Input
                  hasInlineLabel
                  label="Číslo"
                  name="houseNumber"
                  value=""
                  onChange={(value) => {
                    console.log(value);
                  }}
                />
              </div>
            </div>

            <div className="my-2 grid grid-cols-1 gap-4 sm:grid-cols-3">
              <Select
                hasInlineLabel
                label="Obec"
                name="town"
                options={[]}
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
              <Input
                hasInlineLabel
                label="PSČ"
                name="postalCode"
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
              <Select
                hasInlineLabel
                label="Štát"
                name="country"
                options={[
                  { value: 'slovakia', label: 'Slovenská republika' },
                  { value: 'czech', label: 'Česká republika' },
                ]}
                value=""
                onChange={(value) => {
                  console.log(value);
                }}
              />
            </div>
          </div>

          <div>
            <div className="flex w-full items-center">
              <h3 className="mr-2 font-semibold">Ostatné informácie</h3>
              <div className="grow border-b border-blue-500"></div>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <div className="col-span-1 my-2">
                <Switch
                  isChecked={true}
                  label="Dodávateľ je partnerom ENERGO"
                  name="energo_partner"
                  variant={'left'}
                  onChange={(value) => {
                    console.log(value);
                  }}
                />
              </div>
              <div className="col-span-2 flex items-center gap-4">
                <div className="mr-4 w-full sm:w-1/3">
                  <Label hasInlineLabel label="Delenie marže:" name="delenie_marze" />
                </div>
                <div className="sm:w-1/3">
                  <Input
                    hasInlineLabel
                    label="ENERGO (%)"
                    name="energoMargin"
                    type="number"
                    value=""
                    onChange={(value) => {
                      console.log(value);
                    }}
                  />
                </div>
                <div className="sm:w-1/3">
                  <Input
                    hasInlineLabel
                    label="Dodávateľ (%)"
                    name="supplierMargin"
                    type="number"
                    value=""
                    onChange={(value) => {
                      console.log(value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <Switch
                isChecked={true}
                label="Dodávateľ vykupuje el. energiu z fotovoltických elektrární"
                name="supplier_sells"
                variant={'left'}
                onChange={(value) => {
                  console.log(value);
                }}
              />
            </div>
          </div>
        </form>
        <hr />

        <div className="flex flex-col justify-end space-y-4 p-6 sm:flex-row sm:space-x-4 sm:space-y-0">
          <button className="rounded bg-gray-300 px-4 py-2 hover:bg-gray-400" onClick={onClose}>
            Zrušiť
          </button>
          <button className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={onConfirm}>
            Uložiť a zavrieť
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormModal;
