import { FC } from 'react';
import { LiaEdit } from 'react-icons/lia';

import Button from 'core/components/Button';

import Supplier from '../../models/Supplier';
// import ApiTable from 'core/containers/Table/ApiTable';
// import { ColDef } from 'core/containers/Table/components/TableItem';
// import { useState } from 'react';

interface HeaderCardProps {
  supplier: Supplier;
}

// Define the columns configuration
// const columns: ColDef<SupplierData>[] = [
//   { header: 'Názov dodávateľa', field: 'name', route: '/suppliers/:id' },
//   { header: 'IČO', field: 'ico' },
//   { header: 'Kontaktná osoba', field: 'first_contact_person_one_line' },
//   { header: 'Dodávka', field: 'provide_one_line' },
//   { header: 'Výkup z FV', field: 'repurchase_one_line' },
// ];

const Notes: FC<HeaderCardProps> = () => {
  // const [deleteItem, setDeleteItem] = useState<SupplierData | undefined>(undefined);
  // const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  // const handleDeleteClick = (item: SupplierData) => {
  //   setDeleteItem(item);
  //   console.log(item);
  //   setDeleteModalOpen(true);
  // };

  return (
    <div className="rounded-md border border-gray-400">
      <div className="flex w-full items-center justify-between p-4">
        <div className="flex items-center space-x-2">
          <LiaEdit className="h-auto w-8" />
          <h2 className="text-xl font-semibold">Poznámky k dodávateľovi</h2>
        </div>
        <Button isDisabled label={`+ Pridať poznámku`} variant="raised" />
      </div>
      {/*<ApiTable<SupplierData>*/}
      {/*  columns={columns}*/}
      {/*  url="/suppliers"*/}
      {/*  hasActions*/}
      {/*  withoutPagination*/}
      {/*  onDelete={(data) => handleDeleteClick(data)}*/}
      {/*/>*/}
    </div>
  );
};

export default Notes;
