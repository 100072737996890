import { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AppLayout from 'app/AppLayout';
import PrivateRoute from 'core/components/PrivateRoute';

import './app/styles/index.css';

import Login from './app/pages/Login';
import Profile from './app/pages/Profile';
import SupplierDetailPage from './supplier/SupplierDetailPage';
import SuppliersPage from './supplier/SuppliersPage';

const App: FC = () => {
  return (
    <Router>
      <Routes>
        {/* Unauthorized routes */}
        <Route element={<Login />} path="/login" />

        {/* Authorized routes */}
        <Route element={<AppLayout />} path="/">
          <Route element={<PrivateRoute element={<SuppliersPage />} />} path="/suppliers" />
          <Route element={<PrivateRoute element={<SupplierDetailPage />} />} path="/suppliers/:supplierId" />
          <Route element={<PrivateRoute element={<Profile />} />} path="/profile" />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
