import Label from './Label';

interface Props {
  name: string;
  label: string;
  value: string | readonly string[] | number | undefined;
  onChange: (value: string) => void;
  type?: 'email' | 'text' | 'number' | 'phone' | 'password';
  isRequired?: boolean;
  isDisabled?: boolean;
  hasInlineLabel?: boolean;
  isBold?: boolean;
}

function Input({
  name,
  label,
  value,
  onChange,
  type = 'text',
  isRequired = false,
  isDisabled = false,
  hasInlineLabel = false,
  isBold = false,
}: Props) {
  return (
    <div className={hasInlineLabel ? 'flex items-center space-x-2' : 'block'}>
      <Label bold={isBold} hasInlineLabel={hasInlineLabel} isRequired={isRequired} label={label} name={name} />

      <input
        className={`${
          hasInlineLabel ? 'max-w-[calc(100%-100px)] grow' : 'w-full'
        } rounded-md border bg-gray-200 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
        disabled={isDisabled}
        name={name}
        required={isRequired}
        type={type}
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value || '');
        }}
      />
    </div>
  );
}

export default Input;
