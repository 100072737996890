import clsx from 'clsx';

import t from 'core/helpers/t';

interface Props {
  label: string;
  variant?: 'raised' | 'default';
  isDisabled?: boolean | undefined;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
}

function Button({ label, variant = 'default', isDisabled, isLoading = false, className, onClick }: Props) {
  return (
    <button
      className={clsx(
        'rounded px-4 py-2 transition duration-200 ease-in-out',
        {
          'bg-blue-500 hover:bg-blue-700 text-white': variant === 'raised' && !isDisabled,
          'bg-gray-200 hover:bg-gray-400': variant === 'default' && !isDisabled,
          'bg-gray-200 opacity-50 cursor-not-allowed': isDisabled ?? isLoading,
        },
        className
      )}
      disabled={isDisabled ?? isLoading}
      onClick={!isDisabled && !isLoading ? onClick : undefined}
    >
      {isLoading ? <span>{t('Načítavam...')}</span> : label}
    </button>
  );
}

export default Button;
