import { useState, useMemo, FC } from 'react';

import Button from 'core/components/Button';
import Checkbox from 'core/components/Checkbox';
import DeleteModal from 'core/components/DeleteModal';
import FormModal from 'core/components/FormModal';
import SearchBar from 'core/components/SearchBar';
import ApiTable from 'core/containers/Table/ApiTable';
import { ColDef } from 'core/containers/Table/components/TableItem';
import t from 'core/helpers/t';

import Supplier from './models/Supplier';

// Define the columns configuration
const columns: ColDef<Supplier>[] = [
  { header: 'Názov dodávateľa', field: 'name', route: '/suppliers/:id' },
  { header: 'IČO', field: 'ico' },
  { header: 'Kontaktná osoba', field: 'first_contact_person_one_line' },
  { header: 'Dodávka', field: 'provide_one_line' },
  { header: 'Výkup z FV', field: 'repurchase_one_line' },
];

const SupplierPage: FC = () => {
  const [isSearch, setIsSearch] = useState<string>('');
  const [isCheckedElectricity, setIsCheckedElectricity] = useState<boolean>(false);
  const [isCheckedGas, setIsCheckedGas] = useState<boolean>(false);
  const [isPartner, setIsPartner] = useState<boolean>(false);

  const handleSearch = (search: string) => {
    setIsSearch(search);
  };

  const handleCheckboxChangeElectricity = (checked: boolean) => {
    setIsCheckedElectricity(checked);
  };

  const handleCheckboxChangeGas = (checked: boolean) => {
    setIsCheckedGas(checked);
  };

  const handleCheckboxChangePartner = (checked: boolean) => {
    setIsPartner(checked);
  };

  const query = useMemo(() => {
    const parts: string[] = [];

    if (isSearch) {
      parts.push(`search=${isSearch}`);
    }

    if (isCheckedElectricity) {
      parts.push('electricity=1');
    }

    if (isCheckedGas) {
      parts.push('gas=1');
    }

    if (isPartner) {
      parts.push('partner=1');
    }

    return parts.length > 0 ? parts.join('&') : undefined;
  }, [isSearch, isCheckedElectricity, isCheckedGas, isPartner]);

  const [deleteItem, setDeleteItem] = useState<Supplier | undefined>(undefined);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteClick = (item: Supplier) => {
    setDeleteItem(item);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  // const handleCreateClick = () => {
  //   setCreateModalOpen(true);
  // };

  const handleConfirmCreate = () => {
    setCreateModalOpen(false);
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  return (
    <div className="m-4">
      <div className="flex flex-col items-start space-y-4 lg:flex-row lg:items-center lg:justify-between lg:space-y-0">
        <div className="flex flex-wrap items-center space-x-8">
          <SearchBar onSearch={handleSearch} />
          <div className="flex space-x-2">
            <Checkbox
              isChecked={isCheckedElectricity}
              label="elektrická energia"
              onChange={handleCheckboxChangeElectricity}
            />
            <Checkbox isChecked={isCheckedGas} label={t('zemný plyn')} onChange={handleCheckboxChangeGas} />
          </div>
          <Checkbox isChecked={isPartner} label="partner ENERGO" onChange={handleCheckboxChangePartner} />
        </div>
        <div className="flex space-x-4">
          <Button
            label={'Pridať dodávateľa'}
            variant={'raised'}
            onClick={() => {
              setCreateModalOpen(true);
            }}
          />
          <Button isDisabled label={'Export'} />
          <Button isDisabled label={'...'} />
        </div>
      </div>

      <div className="mt-6">
        <ApiTable<Supplier>
          hasActions
          columns={columns}
          query={query}
          url="/suppliers"
          onDelete={(data) => {
            handleDeleteClick(data);
          }}
        />
        {deleteItem && (
          <DeleteModal
            isOpen={isDeleteModalOpen}
            textBody={t('Naozaj chcete zmazať dodávateľa {name}?', { name: deleteItem.name })}
            textDelete={'dodávateľa'}
            textHeader={'Zmazanie dodávateľa'}
            onClose={handleCloseDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        )}

        {isCreateModalOpen && (
          <FormModal isOpen={isCreateModalOpen} onClose={handleCloseCreateModal} onConfirm={handleConfirmCreate} />
        )}
      </div>
    </div>
  );
};

export default SupplierPage;
