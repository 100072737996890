import { FC } from 'react';
import { LiaIndustrySolid, LiaPlugSolid, LiaBurnSolid, LiaCoinsSolid, LiaHandshake } from 'react-icons/lia';

import Tabs, { Tab } from 'core/components/Tabs';
import Supplier from 'supplier/models/Supplier';

interface HeaderCardProps {
  supplier: Supplier;
  tabs: Tab[];
}

const HeaderCard: FC<HeaderCardProps> = ({ supplier, tabs }) => {
  return (
    <div className="m-6 rounded-md border border-gray-400">
      <div className="flex items-start p-6">
        <div className="shrink-0">
          <LiaIndustrySolid className="h-auto w-32 text-blue-500" />
        </div>

        <div className="ml-6">
          <h1 className="text-2xl font-bold">{supplier.name}</h1>

          <div className="mt-2 flex space-x-4 font-bold">
            {supplier.provide_electricity && (
              <div className="flex items-center space-x-1">
                <LiaPlugSolid className="h-auto w-6" />
                <span>Elektrická energia</span>
              </div>
            )}
            {supplier.provide_gas && (
              <div className="flex items-center space-x-1">
                <LiaBurnSolid className="h-auto w-6" />
                <span>Zemný plyn</span>
              </div>
            )}
            {supplier.repurchase_one_line && (
              <div className="flex items-center space-x-1">
                <LiaCoinsSolid className="h-auto w-6" />
                <span>Výkup EE z fotovoltiky</span>
              </div>
            )}
            {supplier.provide_electricity && (
              <div className="flex items-center space-x-1">
                <LiaHandshake className="h-auto w-6" />
                <span>Partner ENERGO</span>
              </div>
            )}
          </div>

          <div className="mt-4 text-gray-600">
            <p>
              {supplier.address.street_name}
              <span className="text-gray-400"> • </span>
              {supplier.address.town}
              <span className="text-gray-400"> • </span>
              {supplier.address.psc}
            </p>
            <p>
              IČO: {supplier.ico}
              {supplier.dic && (
                <>
                  <span className="text-gray-400"> • </span>
                  DIČ: {supplier.dic}
                </>
              )}
            </p>
            {supplier.contact_persons[0] && (
              <p>
                {supplier.contact_persons[0].name}
                {supplier.contact_persons[0].position && (
                  <>
                    <span className="text-gray-400"> • </span>
                    {supplier.contact_persons[0].position}
                  </>
                )}
                <span className="text-gray-400"> • </span>
                <a className="text-blue-500" href={`tel:${supplier.contact_persons[0].mobile}`}>
                  {supplier.contact_persons[0].mobile}
                </a>
                <span className="text-gray-400"> • </span>
                <a className="text-blue-500" href={`mailto:${supplier.contact_persons[0].email}`}>
                  {supplier.contact_persons[0].email}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mx-4">
        <Tabs name="tab" tabs={tabs} />
      </div>
    </div>
  );
};

export default HeaderCard;
