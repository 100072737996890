import { useState, useEffect } from 'react';

const useAuth = () => {
  const [isAuth, setIsAuth] = useState<boolean>(() => !!localStorage.getItem('authToken'));

  const token = localStorage.getItem('authToken');

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuth(!!token);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [token]);

  return isAuth;
};

export default useAuth;
