import { FC } from 'react';
import { LiaInfoCircleSolid } from 'react-icons/lia';

import Button from 'core/components/Button';
import Supplier from 'supplier/models/Supplier';

interface HeaderCardProps {
  supplier: Supplier;
}

const General: FC<HeaderCardProps> = ({ supplier }) => {
  return (
    <div className="m-6 rounded-md border border-gray-400">
      <div className="flex w-full items-center justify-between border-b border-gray-400 p-4">
        <div className="flex items-center space-x-2">
          <LiaInfoCircleSolid className="h-auto w-8" />
          <h2 className="text-xl font-semibold">Základné údaje</h2>
        </div>
        <Button isDisabled label="Upraviť údaje" variant="raised" />
      </div>

      <div className="grid md:grid-cols-2">
        <div className="border-b border-gray-400 p-4 md:border-b-0 md:border-r">
          <div className="mb-6 flex">
            <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
              Názov spoločnosti
            </span>
            <span className="my-1 font-semibold">{supplier.name}</span>
          </div>

          <div className="mb-6">
            <h3 className="font-semibold text-blue-500">Identifikačné údaje</h3>
            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">IČO</span>
              <span className="my-1 font-semibold">{supplier.ico}</span>
            </div>

            {supplier.dic && (
              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">DIČ</span>
                <span className="my-1 font-semibold">{supplier.dic}</span>
              </div>
            )}

            {supplier.ic_dph && (
              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">IČ DPH</span>
                <span className="my-1 font-semibold">{supplier.ic_dph}</span>
              </div>
            )}

            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Platca DPH</span>
              <span className="my-1 font-semibold">{supplier.dph_payer ? 'Áno' : 'Nie'}</span>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="font-semibold text-blue-500">Sídlo spoločnosti / fakturačná adresa</h3>
            <div>
              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Ulica</span>
                <span className="my-1 font-semibold">{supplier.address.street_name}</span>
              </div>
              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Číslo</span>
                <span className="my-1 font-semibold">{supplier.address.number}</span>
              </div>
              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Obec</span>
                <span className="my-1 font-semibold">{supplier.address.town}</span>
              </div>
              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">PSČ</span>
                <span className="my-1 font-semibold">{supplier.address.psc}</span>
              </div>
              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Štát</span>
                <span className="my-1 font-semibold">Slovenská republika</span>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="font-semibold text-blue-500">Bankové údaje</h3>

            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">IBAN</span>
              <span className="my-1 font-semibold">{supplier.iban}</span>
            </div>
            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Banka</span>
              <span className="my-1 font-semibold">{supplier.bank}</span>
            </div>
            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">SWIFT/BIC</span>
              <span className="my-1 font-semibold">{supplier.swift_code}</span>
            </div>
            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                Splatnosť faktúr
              </span>
              <span className="my-1 font-semibold">{supplier.days_of_invoices} dní</span>
            </div>
          </div>
        </div>

        <div className="p-4">
          {/* Energy Suppliers */}
          <div className="mb-6 mt-10">
            <h3 className="font-semibold text-blue-500">Dodávateľ energií</h3>

            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                Dodávateľ el. energie:
              </span>
              <span className="my-1 font-semibold">{supplier.provide_electricity ? 'Áno' : 'Nie'}</span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                Dodávateľ zemného plynu
              </span>
              <span className="my-1 font-semibold">{supplier.provide_gas ? 'Áno' : 'Nie'}</span>
            </div>

            {/*TODO missing partner field*/}
            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                Dodávateľ je partnerom ENERGO
              </span>
              <span className="my-1 font-semibold">{supplier.provide_electricity ? 'Áno' : 'Nie'}</span>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="font-semibold text-blue-500">Korešpondenčná adresa</h3>
            <>
              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Ulica</span>
                <span className="my-1 font-semibold">{supplier.mail_address.street_name}</span>
              </div>

              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Číslo</span>
                <span className="my-1 font-semibold">{supplier.mail_address.number}</span>
              </div>

              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Obec</span>
                <span className="my-1 font-semibold">{supplier.mail_address.town}</span>
              </div>

              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">PSČ</span>
                <span className="my-1 font-semibold">{supplier.mail_address.psc}</span>
              </div>

              <div className="flex">
                <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">Štát</span>
                {/*TODO missing country name field*/}
                <span className="my-1 font-semibold">{supplier.mail_address.country_id}</span>
              </div>
            </>
          </div>

          {/* Margin Distribution only for ENERGO partners */}
          {/*TODO ENERGO partners only*/}
          <div className="mb-6">
            <h3 className="font-semibold text-blue-500">Spôsob delenia celkovej marže</h3>

            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                Marža ENERGO (%)
              </span>
              {/*TODO missing field*/}
              <span className="my-1 font-semibold">50</span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-gray-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                Marža dodávateľ (%)
              </span>
              {/*TODO missing field*/}
              <span className="my-1 font-semibold">50</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
