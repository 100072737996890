import axios from 'axios';

const API_ROUTE = import.meta.env.VITE_APP_API_PATH;

// Store session token in localStorage
export const login = async (email: string, password: string): Promise<boolean> => {
  try {
    const response = await axios.post<{ access_token: string }>(`${API_ROUTE}/auth/login`, { email, password });
    const { access_token } = response.data;
    if (access_token) {
      localStorage.setItem('authToken', access_token);
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

// Clear token on logout
export const logout = async (): Promise<void> => {
  try {
    const token = localStorage.getItem('authToken');

    if (token) {
      try {
        await axios.post(`${API_ROUTE}/auth/logout`, {}, { headers: { Authorization: `Bearer ${token}` } });
      } catch (logoutError: unknown) {
        // Handle logout error if any

        // If the error indicates that the token is expired or invalid,
        // clear the token from localStorage
        if (
          logoutError &&
          typeof logoutError === 'object' &&
          'response' in logoutError &&
          logoutError.response &&
          typeof logoutError.response === 'object' &&
          'status' in logoutError.response &&
          logoutError.response.status === 401
        ) {
          // Unauthorized
        }
      } finally {
        // Ensure that the token is removed from localStorage in all cases
        localStorage.removeItem('authToken');
      }
    }
  } catch {
    // Ensure token removal in case of any other unexpected error
    localStorage.removeItem('authToken');
  }
};

// Check if the user is authenticated by checking the token
export const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('authToken');
  return !!token;
};

// Send a password reset email to the provided email address
export const sendPasswordResetEmail = async (email: string): Promise<boolean> => {
  try {
    const response = await axios.post(`${API_ROUTE}/auth/reset-password`, { email });
    // Assuming the API responds with a success message or similar
    return response.status === 200;
  } catch {
    return false; // Return false if there was an error
  }
};

// Reset the password for the user
export const resetPassword = async (email: string, newPassword: string): Promise<boolean> => {
  try {
    const response = await axios.post(`${API_ROUTE}/auth/reset-password-confirm`, { email, newPassword });
    return response.status === 200;
  } catch {
    return false; // Return false if there was an error
  }
};
