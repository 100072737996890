import { LiaEditSolid, LiaTrashAltSolid } from 'react-icons/lia';

export interface ColDef<D> {
  field: keyof D;
  header: string;
  route?: string;
}

interface Props<D> {
  columns: ColDef<D>[];
  data: D;
  hasActions: boolean;
  onDelete: () => void;
}

function TableItem<D extends { id: string | number }>({ columns, data, hasActions, onDelete }: Props<D>) {
  return (
    <tr className="odd:bg-gray-200">
      {columns.map((c) => (
        <td className="px-4 py-2" key={String(c.field)}>
          {data[c.field] ? (
            c.route ? (
              <a className={'text-blue-500 hover:text-blue-700'} href={c.route.replace(':id', data.id as string)}>
                {String(data[c.field])}
              </a>
            ) : (
              String(data[c.field])
            )
          ) : (
            ''
          )}
        </td>
      ))}
      {hasActions && (
        <td>
          <div className="flex items-center justify-center space-x-2">
            <button>
              <LiaEditSolid />
            </button>
            <button onClick={onDelete}>
              <LiaTrashAltSolid />
            </button>
          </div>
        </td>
      )}
    </tr>
  );
}

export default TableItem;
