import axios from 'axios';

const API_ROUTE = import.meta.env.VITE_APP_API_PATH;

export const getTableData = async <D>(url: string, query?: string): Promise<D> => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    throw new Error('Auth token missing');
  }

  let route = `${API_ROUTE}${url}`;
  if (query) {
    route += `?${query}`;
  }

  const response = await axios.get<D>(route, { headers: { Authorization: `Bearer ${token}` } });

  return response.data;
};
