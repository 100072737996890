import { useState, useEffect, FormEvent, FC } from 'react';
import { LiaPhoneSolid, LiaEnvelope } from 'react-icons/lia';
import { useNavigate, useLocation } from 'react-router-dom';

import energoLogo2024 from 'app/assets/images/energo-logo-2024.png';
import oceanLogoDark from 'app/assets/images/ocean-logo-dark.png';
import oceanLogoOutline from 'app/assets/images/ocean-logo-outline.png';
import loginLoopLow from 'app/assets/login-loop-low-q.mp4';
import Button from 'core/components/Button';
import Input from 'core/components/Input';
import useIsSmallScreen from 'core/hooks/useIsSmallScreen';
import { login, resetPassword, sendPasswordResetEmail } from 'core/services/auth';

const Login: FC = () => {
  const [email, setEmail] = useState<string>('test@example.com');
  const [password, setPassword] = useState<string>('password');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useIsSmallScreen();
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      setIsResettingPassword(true);
    }
  }, [location]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const success = await login(email, password);
    if (success) {
      navigate('/suppliers');
    } else {
      setError('Invalid email or password');
    }
  };

  const handleForgotPasswordSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const success = await sendPasswordResetEmail(email);
    if (success) {
      setError('Password reset email sent. Please check your inbox.');
      setIsForgotPassword(false); // Reset form state
    } else {
      setError('Failed to send password reset email.');
    }
  };

  const handleResetPasswordSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords don't match.");
      return;
    }

    const success = await resetPassword(email, newPassword);
    if (success) {
      setError('Password successfully reset. You can now log in.');
      setIsResettingPassword(false);
      setEmail('');
      setNewPassword('');
      setConfirmPassword('');
    } else {
      setError('Failed to reset password.');
    }
  };

  const handleCancel = () => {
    setIsForgotPassword(false);
    setIsResettingPassword(false);
    setError('');
  };

  return (
    <div className="flex min-h-screen md:flex-row">
      {/* Left column */}
      <div
        className={`flex w-full flex-col md:w-1/2 ${
          isSmallScreen ? 'justify-start' : 'justify-between'
        } relative min-h-screen items-center bg-gray-100 p-4 md:bg-white`}
      >
        {/* Form Container */}
        <div className={`w-full max-w-md ${isSmallScreen ? 'mt-2' : 'flex grow flex-col justify-center'}`}>
          {isSmallScreen && showContent && (
            <div className="relative my-2 flex justify-center opacity-100 transition-opacity duration-500">
              {/* TODO: urgent */}
              {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
              <img alt="Supplier Logo" className="max-w-s" src={oceanLogoDark} />
            </div>
          )}

          <h2 className={`text-2xl font-bold ${isSmallScreen ? 'mb-2' : 'mb-8'} text-center text-gray-800`}>
            {isResettingPassword || isForgotPassword ? 'Obnova hesla pre prihlásenie' : 'Prihlásenie do systému'}
          </h2>
          {error && <p className={`text-sm text-red-500 ${isSmallScreen ? 'mb-2' : 'mb-4'}`}>{error}</p>}
          <form
            onSubmit={(d) => {
              if (isResettingPassword) {
                handleResetPasswordSubmit(d).catch((e: unknown) => {
                  // eslint-disable-next-line no-console
                  console.error(e);
                });
              } else if (isForgotPassword) {
                handleForgotPasswordSubmit(d).catch((e: unknown) => {
                  // eslint-disable-next-line no-console
                  console.error(e);
                });
              } else {
                handleSubmit(d).catch((e: unknown) => {
                  // eslint-disable-next-line no-console
                  console.error(e);
                });
              }
            }}
          >
            {!isResettingPassword && (
              <div className={isSmallScreen ? 'mb-2' : 'mb-4'}>
                <Input
                  isBold
                  label={isForgotPassword ? 'Zadajte Váš email pre prihlásenie' : 'Email'}
                  name="email"
                  type="email"
                  value={email}
                  onChange={(value) => {
                    setEmail(value);
                  }}
                />
              </div>
            )}
            {isResettingPassword && (
              <>
                <div className={isSmallScreen ? 'mb-2' : 'mb-4'}>
                  <Input
                    isBold
                    label="Nové heslo"
                    name="newPassword"
                    type="password"
                    value={newPassword}
                    onChange={(value) => {
                      setNewPassword(value);
                    }}
                  />
                </div>
                <div className={isSmallScreen ? 'mb-2' : 'mb-4'}>
                  <Input
                    isBold
                    label="Potvrdenie nového hesla"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(value) => {
                      setConfirmPassword(value);
                    }}
                  />
                </div>
              </>
            )}
            {!isResettingPassword && !isForgotPassword && (
              <>
                <div className={isSmallScreen ? 'mb-2' : 'mb-4'}>
                  <Input
                    isBold
                    label="Heslo"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(value) => {
                      setPassword(value);
                    }}
                  />
                  <div className="mt-2 text-right">
                    <button
                      className="text-blue-500 hover:text-blue-700 hover:underline"
                      type="button"
                      onClick={() => {
                        setIsForgotPassword(true);
                      }}
                    >
                      Zabudli ste heslo ?
                    </button>
                  </div>
                </div>
                <Button
                  className={`w-full ${isSmallScreen ? 'mt-2' : 'mt-8'}`}
                  label="POKRAČOVAŤ DO OCEAN"
                  variant="raised"
                />
              </>
            )}
            {isForgotPassword && (
              <div className="mt-10 flex justify-between">
                <Button className="w-full" label="Odoslať odkaz na obnovenie" variant="raised" />
                <Button className="ml-2" label="Späť" variant="default" onClick={handleCancel} />
              </div>
            )}
            {isResettingPassword && (
              <div className="mt-10 flex justify-between">
                <Button className="w-full" label="Vytvoriť nové heslo" variant="raised" />
                <Button className="ml-2" label="Späť" variant="default" onClick={handleCancel} />
              </div>
            )}
          </form>
        </div>

        {/* Bottom text and logo */}
        <div className="absolute bottom-4 w-full px-4 text-center">
          <p className="mb-2 text-sm text-gray-800 md:text-base">
            Klientský portál prevádzkovaný spoločnosťou
            <a href="https://energo.sk" rel="noreferrer" target="_blank">
              <img
                alt="Energo Logo"
                className="m-2 inline-block w-16 transition-opacity duration-500 md:w-20"
                src={energoLogo2024}
                style={{ opacity: showContent ? 1 : 0 }}
              />
            </a>
          </p>
          <p
            className={`flex flex-col text-gray-500 md:flex-row ${isSmallScreen ? '' : 'gap-4'} items-center justify-center text-center`}
          >
            <a className="inline-flex items-center text-gray-500 hover:text-gray-700" href="mailto:dispecing@energo.sk">
              <LiaEnvelope className="mr-2 inline-block" /> dispecing@energo.sk
            </a>
            <a className="inline-flex items-center text-gray-500 hover:text-gray-700" href="tel:+421910464243">
              <LiaPhoneSolid className="mr-2 inline-block" /> +421 910 464 243
            </a>
          </p>
        </div>
      </div>

      {/* Right column with video and logo */}
      {!isSmallScreen && (
        <div className="relative hidden w-full md:block md:w-1/2">
          <video
            autoPlay
            loop
            muted
            className={`absolute left-0 top-0 size-full object-cover transition-opacity duration-500 ${showContent ? 'opacity-100' : 'opacity-0'}`}
          >
            <source src={loginLoopLow} type="video/mp4" />
            Váš prehliadač nepodporuje daný typ videa.
          </video>

          <div className="relative size-full">
            <img
              alt="Supplier Logo"
              className={`absolute inset-0 m-auto max-w-md transition-opacity duration-500 ${showContent ? 'opacity-100' : 'opacity-0'}`}
              src={oceanLogoOutline}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
