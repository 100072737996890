import { FC, useState } from 'react';
import { LiaUserTieSolid } from 'react-icons/lia';

import Button from 'core/components/Button';
import DeleteModal from 'core/components/DeleteModal';
import ApiTable from 'core/containers/Table/ApiTable';
import { ColDef } from 'core/containers/Table/components/TableItem';

import ContactPerson from '../../models/ContactPerson';
import Supplier from '../../models/Supplier';

interface HeaderCardProps {
  supplier: Supplier;
}

// Define the columns configuration
const columns: ColDef<ContactPerson>[] = [
  { header: 'Meno, Priezvisko, Titul', field: 'name', route: '/contact-persons/:id/' },
  { header: 'Telefón', field: 'mobile' },
  { header: 'E-mail', field: 'email' },
  { header: 'Funkcia', field: 'position' },
  { header: 'Poznámka', field: 'note' },
];

const ContactPersons: FC<HeaderCardProps> = ({ supplier }) => {
  const [deleteItem, setDeleteItem] = useState<ContactPerson | undefined>(undefined);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteClick = (item: ContactPerson) => {
    setDeleteItem(item);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  return (
    <div className="rounded-md border border-gray-400">
      <div className="flex w-full items-center justify-between p-4">
        <div className="flex items-center space-x-2">
          <LiaUserTieSolid className="h-auto w-8" />
          <h2 className="text-xl font-semibold">Kontaktné osoby</h2>
        </div>
        <Button isDisabled label={`+ Pridať kontaktnú osobu`} variant="raised" />
      </div>
      <ApiTable<ContactPerson>
        hasActions
        hasPagination
        columns={columns}
        url={`/suppliers/${supplier.id}/contact-persons`}
        onDelete={(data) => {
          handleDeleteClick(data);
        }}
      />
      {deleteItem && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          textBody={`Naozaj chcete zmazať kontaktnú osobu ${deleteItem.name}?`}
          textDelete={'kontaktnú osobu'}
          textHeader={'Zmazanie kontaktnú osobu'}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default ContactPersons;
