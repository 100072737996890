import { ChangeEvent, FC } from 'react';
import { LiaCheckSolid } from 'react-icons/lia';

interface CheckboxProps {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  isDisabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({ isChecked, onChange, label, isDisabled = false }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className="flex items-center space-x-2">
      <button
        className={`relative flex size-5 items-center justify-center rounded-sm border-2
        ${isChecked ? 'border-black bg-white' : 'border-gray-400 bg-gray-100'}
        ${isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
        onClick={() => {
          if (!isDisabled) {
            onChange(!isChecked);
          }
        }}
      >
        {isChecked && <LiaCheckSolid className="size-4 text-black" />}
      </button>
      <input checked={isChecked} className="hidden" disabled={isDisabled} type="checkbox" onChange={handleChange} />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <label
        className={`text-gray-700 ${isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
        onClick={() => {
          if (!isDisabled) {
            onChange(!isChecked);
          }
        }}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
