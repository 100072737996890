import { ColDef } from './TableItem';

interface Props<D> {
  columns: ColDef<D>[];
  hasActions: boolean;
}

function TableHeader<D>({ columns, hasActions }: Props<D>) {
  return (
    <thead className="border-b border-gray-300">
      <tr>
        {columns.map((c) => (
          // @ts-ignore TODO: improve typings
          <th className="px-4 py-2 text-left" key={c.field}>
            {c.header}
          </th>
        ))}
        {hasActions && (
          <th className="px-4 py-2 text-left" key="actions">
            Akcia
          </th>
        )}
      </tr>
    </thead>
  );
}

export default TableHeader;
