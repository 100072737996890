import { useState, useEffect } from 'react';

const SMALL_SCREEN_BREAKPOINT = 768;

const useIsSmallScreen = () => {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= SMALL_SCREEN_BREAKPOINT);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= SMALL_SCREEN_BREAKPOINT);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isSmallScreen;
};

export default useIsSmallScreen;
