import { FC, useState } from 'react';

import Button from './Button';

interface SearchBarProps {
  onSearch: (query: string) => void;
  isDisabled?: boolean | undefined;
}

const SearchBar: FC<SearchBarProps> = ({ onSearch, isDisabled }) => {
  const [query, setQuery] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSearch = () => {
    onSearch(query);
  };

  return (
    <div className="flex items-center space-x-2">
      <input
        className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        disabled={isDisabled}
        placeholder="Search..."
        type="text"
        value={query}
        onChange={handleChange}
      />
      <Button isDisabled={isDisabled} label={'Hľadať'} onClick={handleSearch} />
    </div>
  );
};

export default SearchBar;
