import { useEffect, useState } from 'react';

import { getTableData } from '../../services/table';

import { ColDef } from './components/TableItem';

import Table from './index';

interface Props<D> {
  url: string;
  columns: ColDef<D>[];
  hasActions: boolean;
  query?: string | undefined;
  hasPagination?: boolean;
  onDelete: (data: D) => void;
}

function ApiTable<D extends { id: string | number }>({
  url,
  columns,
  hasActions = false,
  query,
  hasPagination = true,
  onDelete,
}: Props<D>) {
  const [data, setData] = useState<D[]>([]);

  useEffect(() => {
    getTableData<{ data: D[] }>(url, query)
      .then(({ data }) => {
        setData(data);
      })
      .catch((_e: unknown) => {
        // console.error(e);
      });
  }, [url, query]);

  return (
    <Table<D>
      columns={columns}
      data={data}
      hasActions={hasActions}
      hasPagination={hasPagination}
      limit={0}
      onDelete={onDelete}
    />
  );
}

export default ApiTable;
