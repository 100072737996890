import { FC, useState } from 'react';
import { LiaIndustrySolid, LiaUserCircle } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';

import styles from './TopBar.module.scss';

interface TopBarProps {
  onLogout: () => void;
}

const TopBar: FC<TopBarProps> = ({ onLogout }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={styles.topBar}>
      <div className={styles.leftSection}>
        <div>
          <div className={styles.title}>
            <LiaIndustrySolid className="size-10 text-blue-500" />
            <h1>Zoznam dodávateľov</h1>
          </div>
          <div className="ml-4 text-gray-400">
            <small>Ocean / Zoznam dodávateľov</small>
          </div>
        </div>
      </div>
      <div className={styles.dropdownContainer}>
        <LiaUserCircle
          className="size-10 cursor-pointer text-blue-500"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
        />
        {dropdownOpen && (
          <div className={styles.dropdownMenu}>
            <button
              className={styles.dropdownItem}
              onClick={() => {
                navigate('/profile');
                setDropdownOpen(false);
              }}
            >
              Profil
            </button>
            <button className={styles.dropdownItem} onClick={onLogout}>
              Odhlásiť sa
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar;
