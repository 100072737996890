import { useState } from 'react';

import TableFooter from './components/TableFooter';
import Header from './components/TableHeader';
import TableItem, { ColDef } from './components/TableItem';
import getPagesNumber from './helpers/getPagesNumber';

interface Props<D> {
  data: D[];
  columns: ColDef<D>[];
  limit: number;
  hasActions: boolean;
  hasPagination?: boolean | undefined;
  onDelete: (data: D) => void;
}

function Table<D extends { id: string | number }>({
  columns,
  data,
  hasActions = false,
  hasPagination = true,
  onDelete,
}: Props<D>) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20); // TODO: implement api pagination

  const displayed = data.slice(page * limit, page * limit + limit);

  return (
    <>
      <div className="overflow-x-auto overflow-y-hidden rounded-md border border-gray-300">
        <table className="min-w-full border-collapse">
          <Header columns={columns} hasActions={hasActions} />
          <tbody>
            {displayed.map((item, index) => (
              <TableItem<D>
                columns={columns}
                data={item}
                hasActions={hasActions}
                key={index}
                onDelete={() => {
                  onDelete(item);
                }}
              />
            ))}
          </tbody>
        </table>
      </div>

      {/* Conditional rendering for pagination */}
      {hasPagination && (
        <TableFooter
          currentPage={page}
          limit={limit}
          pages={getPagesNumber(data.length || 0, limit)}
          onChangeLimit={setLimit}
          onPageSelect={setPage}
        />
      )}
    </>
  );
}

export default Table;
