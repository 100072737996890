import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { isAuthenticated } from '../services/auth';

interface PrivateRouteProps {
  element: ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ element }) => {
  return isAuthenticated() ? element : <Navigate replace to="/login" />;
};

export default PrivateRoute;
