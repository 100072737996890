interface Props {
  name: string;
  label: string;
  isRequired?: boolean | undefined;
  hasInlineLabel?: boolean | undefined;
  bold?: boolean | undefined;
}

function Label({ name, label, isRequired = false, hasInlineLabel = false, bold = false }: Props) {
  return (
    <label
      className={`text-sm leading-6 text-gray-900 ${hasInlineLabel ? 'w-auto min-w-10' : 'mb-1 block'} ${
        bold ? 'font-bold' : 'font-normal'
      }`}
      htmlFor={name}
    >
      {label}
      {isRequired && <span className="inline pl-1 text-red-500">*</span>}
    </label>
  );
}

export default Label;
