import { useState, useEffect, ReactNode, useCallback, FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export interface Tab {
  label: string;
  key: string;
  path: string;
  content: ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  name: string;
}

const Tabs: FC<TabsProps> = ({ tabs, name }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const getCurrentTabFromUrl = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get(name);
    return tab ?? tabs[0]?.key; // Safely check for tabs[0]
  }, [location.search, name, tabs]);

  useEffect(() => {
    if (tabs.length > 0) {
      const currentTabKey = getCurrentTabFromUrl();
      const activeIndex = tabs.findIndex((tab) => tab.key === currentTabKey);
      setActiveTab(activeIndex !== -1 ? activeIndex : 0);
    }
  }, [tabs, location.search, getCurrentTabFromUrl]);

  const handleTabClick = (index: number, path: string) => {
    setActiveTab(index);

    const params = new URLSearchParams(location.search);
    params.set(name, tabs[index].key);
    navigate(`${path}?${params.toString()}`);
  };

  if (tabs.length === 0) {
    return <div>Načítavam...</div>;
  }

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-300">
        {tabs.map((tab, index) => (
          <button
            className={`px-4 py-2 text-sm font-medium transition-colors duration-300
            ${activeTab === index ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'}`}
            key={tab.key}
            onClick={() => {
              handleTabClick(index, tab.path);
            }}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
