import { useState } from 'react';
import { HiChevronDown } from 'react-icons/hi'; // Import an arrow icon

import Label from './Label';

interface Option {
  value: string | number;
  label: string;
}

interface Props {
  name: string;
  label: string;
  value: string | number | undefined;
  onChange: (value: null | string | number) => void;
  options: Option[];
  isRequired?: boolean;
  isDisabled?: boolean;
  hasInlineLabel?: boolean;
}

function Select({
  name,
  label,
  value,
  onChange,
  options,
  isRequired = false,
  isDisabled = false,
  hasInlineLabel = false,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()));

  const handleSelect = (selectedValue: string | number) => {
    onChange(selectedValue);
    setIsOpen(false);
  };

  return (
    <div className={hasInlineLabel ? 'flex items-center space-x-2' : 'block'}>
      <Label hasInlineLabel={hasInlineLabel} isRequired={isRequired} label={label} name={name} />

      <div className="relative">
        <div
          className={`${
            hasInlineLabel ? 'min-w-[calc(100%-100px)] grow' : 'w-full'
          } flex cursor-pointer items-center justify-between rounded-md border bg-gray-200 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500`}
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
          onKeyDown={() => {
            // TODO: Improve accessibility by using button element
          }}
        >
          <span>{value ? options.find((option) => option.value === value)?.label : 'Select an option'}</span>
          <HiChevronDown className="ml-2" />
        </div>

        {isOpen && (
          <div className="absolute z-10 mt-2 max-h-60 w-full overflow-y-auto rounded-md border border-gray-300 bg-white shadow-lg">
            <input
              autoFocus
              className="w-full border-b px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={isDisabled}
              placeholder="Search..."
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />

            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  className={`cursor-pointer px-3 py-2 hover:bg-gray-200 ${
                    value === option.value ? 'bg-blue-100' : ''
                  }`}
                  key={option.value}
                  onClick={() => {
                    handleSelect(option.value);
                  }}
                  onKeyDown={() => {
                    // improve accessibility by using active element not div - button or something
                  }}
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className="px-3 py-2 text-gray-500">No options found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Select;
