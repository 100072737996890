import { FC } from 'react';
import { LiaExclamationCircleSolid, LiaTimesSolid } from 'react-icons/lia';

interface DeleteModalProps {
  isOpen: boolean;
  textBody: string;
  textDelete: string;
  textHeader: string;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({ isOpen, textBody, textDelete, textHeader, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500/50">
      {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
      <div className="rounded-sm bg-white" style={{ boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)' }}>
        <div className="flex items-center justify-between p-6">
          <h2 className="flex-1 text-center text-xl font-bold">{textHeader}</h2>
          <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
            <LiaTimesSolid size={20} />
          </button>
        </div>
        <hr />
        <div className="flex flex-col items-center p-6">
          <LiaExclamationCircleSolid className="my-2 text-red-500" size={60} />
          <p className="mx-10 text-center">{textBody}</p>
        </div>
        <hr />
        <div className="flex justify-center space-x-4 p-6">
          <button className="rounded bg-gray-300 px-4 py-2 hover:bg-gray-400" onClick={onClose}>
            Zrušiť
          </button>
          <button className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600" onClick={onConfirm}>
            Zmazať {textDelete}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
