import clsx from 'clsx';
import { FC } from 'react';

interface Props {
  pages: number;
  currentPage: number;
  onPageSelect: (page: number) => void;
  limit?: number;
  /** If not present, limit isDisabled */
  onChangeLimit?: (limit: number) => void;
}

const TableFooter: FC<Props> = ({ onPageSelect, onChangeLimit, limit, currentPage, pages }) => {
  return (
    <div className="mt-2">
      <div className="flex flex-row items-center justify-between">
        <div>
          {pages > 0 &&
            Array.from({ length: pages }, (_, i) => (
              <button
                className={clsx('bg-gray-200 px-2 py-1 hover:bg-gray-400', {
                  'bg-gray-300': currentPage === i + 1,
                })}
                key={i}
                onClick={() => {
                  onPageSelect(i + 1);
                }}
              >
                {i + 1}
              </button>
            ))}
        </div>
        <div>
          <select
            disabled={!onChangeLimit}
            value={limit}
            onChange={(e) => {
              if (onChangeLimit) {
                onChangeLimit(Number(e.currentTarget.value));
              }
            }}
          >
            <option disabled value="">
              Select limit
            </option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
